export const config = {
  header: {
    title: 'Downswood Alpacas',
    menuItems: [
      {
        name: 'About us',
        link: 'about',
      },
      {
        name: 'Activities',
        link: 'offers',
      },
      {
        name: 'Contact',
        link: 'location',
      },
      {
        name: 'Book Now',
        link: '/contact',
        external: true,
      },
    ],
  },
  landing: {
    title: 'Alpaca Activities',
    subTitle: 'Alpaca walks, arts and crafts in the heart of Wiltshire',
    background: '#fff',
    backgroundImage:
      'https://res.cloudinary.com/dgdniqfi9/image/upload/v1551264032/alpacas/mainbackgroundalpacas.jpg',
    backgroundImageMob:
      'https://res.cloudinary.com/dgdniqfi9/image/upload/v1551357057/alpacas/alpaca_mob.jpg',
  },
  about: {
    title: 'About Us',
    subTitle1: 'What Do We Do?',
    text1: [
      '"Downswood Alpacas" comprises 10 breeding females and their young (when they are born!) who live off site.',

      'There are also 5 males who make up the walking team who are based at home here with us in a beautiful setting on the edge of Salisbury Plain.',

      'Our aim is to provide a friendly, relaxed environment for our visitors to experience alpacas and appreciate the beauty of the natural world.',

      'Alpacas are relaxing, intriguing, amusing and can be very therapeutic!  Apart from their characters - and they all have their own particular idiosyncrasies!! - they produce wonderful fibre which can be used for many different purposes from making high quality garments to bedding, felting and even insulation!',

      'Our walks give the opportunity to get to know alpacas and learn some of the interesting facts about them as well as to discover many things about the flora and fauna that may be encountered on the trail.',

      'We also offer the chance to try out some techniques associated with fibre production such as preparing a fleece, starting to learn how to spin, and practising felting - including making a felt picture or needle felting a small animal.',

      'Drawing and painting alpacas or other animals are further options that are available as part of the experience!',
    ], //eslint-disable-line
    desktopImage:
      'https://res.cloudinary.com/dgdniqfi9/image/upload/v1559644905/alpacas/alpacas_sunrise-alpacas.jpg',
    mobileImage:
      'https://res.cloudinary.com/dgdniqfi9/image/upload/c_scale,w_500/v1551349139/alpacas/alpacas-line.jpg',
    subTitle2: 'Where Are We?',
    address: ['Downswood', 'Great Cheverell', 'Devizes', 'SN10 5TW'],
    phone: '01380 812174',
    email: 'info@downswoodalpacas.co.uk',
  },
  offers: {
    cards: [
      {
        title: 'OFFER 1',
        info: ['1 HOUR VISIT', 'Meet and walk the alpacas'],
        // img: 'https://res.cloudinary.com/dgdniqfi9/image/upload/v1551382660/alpacas/al4.jpg',
        img:
          'https://res.cloudinary.com/dgdniqfi9/image/upload/v1572900522/alpacas/pacca1.jpg',
        included: [
          'Up to 5 individuals leading or up to 10 people sharing.',
          'Meet the alpacas, take them for a wander round the woodland path or paddock.',
          'Learn some interesting alpaca facts.',
          'View some of the things we have made with their fleece.',
          '£20 per individual; £30 per two people sharing; £10 each adult/child accompanying and not leading.',
          'We can do an exclusive private family/group session, prices as above -  minimum booking £80',
          'Under 2’s free!',
        ],
      },
      {
        title: 'OFFER 2',
        info: [
          '3 HOUR WORKSHOP',
          'Meet alpacas',
          'Craft workshop',
          'Optional walk',
        ],
        // img: 'https://res.cloudinary.com/dgdniqfi9/image/upload/v1551382460/alpacas/al.jpg',
        img:
          'https://res.cloudinary.com/dgdniqfi9/image/upload/v1572900512/alpacas/pacca2.jpg',
        included: [
          'Minimum 4 people, maximum 10',
          'Meet the alpacas (optional walk)',
          'Craft work',
          'Choose from the following:',
          '- Make a 3d felt animal (needle felting)',
          '- Flat felt a picture or design',
          '- Sketch/paint animals',
          '- Fleece preparation/spinning',
          '£40 per person',
        ],
      },
      {
        title: 'OFFER 3',
        info: ['DAY VISIT', '10:30 - 3:30'],
        // img: 'https://res.cloudinary.com/dgdniqfi9/image/upload/v1551382525/alpacas/al3.jpg',
        img:
          'https://res.cloudinary.com/dgdniqfi9/image/upload/v1572900504/alpacas/pacca3.jpg',
        included: [
          'Minimum 5 people, maximum 10',
          'Time: 10:30 - 3:30',
          'Tailored to suit from any of the activities from offers 1 or 2',
          'Bring  your own lunch.',
          'This is designed to be a relaxing day with your family/friends/colleagues.',
          'Well suited for corporate outings',
          'Please contact us for options.',
          '£65 per person',
        ],
      },
      {
        title: 'OFFER 4',
        info: ["MINI'S VISIT", '1 hour'],
        // img: 'https://res.cloudinary.com/dgdniqfi9/image/upload/v1551382525/alpacas/al3.jpg',
        img:
          'https://res.cloudinary.com/dgdniqfi9/image/upload/v1551382525/alpacas/al3.jpg',
        included: [
          'A special time for the under 8’s !  …. (or young hearted adults !!).',
          'Meet and feed the alpacas, go for a short accompanied walk and enjoy some other fun alpaca activities !',
          'NB:  Each under 8 must be accompanied by a paying adult',
          'Adults £20',
          'Under 8’s £10',
          'Please contact us for further details.',
          'Under 2’s free!',
        ],
      },
    ],
  },
  team: {
    bigImage:
      'https://res.cloudinary.com/dgdniqfi9/image/upload/v1548513809/alpacas/alpaca-bg.jpg',
    bigImageMob:
      'https://res.cloudinary.com/dgdniqfi9/image/upload/c_scale,w_900/v1548535111/alpacas/alpaca-stare.jpg',
    people: [
      {
        title: 'Clare and Mark Smallman',
        text:
          'Mark and Clare Smallman have worked with animals for over 30 years. As well as working with the alpacas Mark is a professional painter and sculptor, plays in three rock bands and trains horses. Clare also runs ‘Spiritus Equis’ which provides equine therapy and learning to help people with autism, PTSD, emotional health, wellbeing and family relationships. Mark and Clare share their home with the alpacas, four dogs, several horses, chickens and family.', //eslint-disable-line
        img: [
          {
            move: -40,
            src:
              'https://res.cloudinary.com/dgdniqfi9/image/upload/v1548532092/alpacas/Clare-photo.jpg',
          },
          {
            move: 40,
            src:
              'https://res.cloudinary.com/dgdniqfi9/image/upload/v1548532024/alpacas/Mark-photo.jpg',
          },
        ],
      },
      {
        title: 'The A Team',
        text:
          'Our boy alpacas all have different characters, we love having them so close to our home so we can enjoy their wonderful company.  We can watch them as they go about the routine of being an alpaca - plenty of grazing, sitting down, snoozing and chewing the cud, along with the odd tiff and lots of investigating things of interest!', //eslint-disable-line
        img: [
          {
            move: -80,
            src:
              'https://res.cloudinary.com/dgdniqfi9/image/upload/v1559138467/alpacas/alfie.png',
          },
          {
            move: 0,
            src:
              'https://res.cloudinary.com/dgdniqfi9/image/upload/v1559078527/alpacas/cria.jpg',
          },
          {
            move: 80,
            src:
              'https://res.cloudinary.com/dgdniqfi9/image/upload/v1568875303/alpacas/leonard.jpg',
          },
        ],
      },
    ],
  },
  gallery: {
    images: [
      {
        get: (h, w) =>
          `https://res.cloudinary.com/dgdniqfi9/image/upload/c_fill,h_${h},w_${w}/v1551697823/alpacas/sunrise-alpacas.jpg`, //eslint-disable-line
      },
      {
        get: (h, w) =>
          `https://res.cloudinary.com/dgdniqfi9/image/upload/c_fill,h_${h},w_${w}/v1551349139/alpacas/alpacas-line.jpg`, //eslint-disable-line
      },
      {
        get: (h, w) =>
          `https://res.cloudinary.com/dgdniqfi9/image/upload/c_fill,h_${h},w_${w}/v1551261965/alpacas/main-bg-img.jpg`, //eslint-disable-line
      },
      {
        get: (h, w) =>
          `https://res.cloudinary.com/dgdniqfi9/image/upload/c_fill,h_${h},w_${w}/v1551347263/alpacas/card1.jpg`, //eslint-disable-line
      },
      {
        get: (h, w) =>
          `https://res.cloudinary.com/dgdniqfi9/image/upload/c_fill,h_${h},w_${w}/v1551262313/alpacas/bg.jpg`, //eslint-disable-line
      },
      {
        get: (h, w) =>
          `https://res.cloudinary.com/dgdniqfi9/image/upload/c_fill,h_${h},w_${w}/v1551135969/alpacas/alpaca1.jpg`, //eslint-disable-line
      },
      {
        get: (h, w) =>
          `https://res.cloudinary.com/dgdniqfi9/image/upload/c_fill,h_${h},w_${w}/v1551382525/alpacas/al3.jpg`, //eslint-disable-line
      },
    ],
  },
  testimonials: [
    {
      name: 'Anne',
      date: 'July 2019',
      text:
        'What a privilege to take a break from the daily routine and spend a day getting to know these delightful, gentle creatures.',
      imgSrc: '', //eslint-disable-line
    },
    {
      name: 'Simon',
      date: 'February 2019',
      text: 'What a splendid way to spend a day!',
      imgSrc:
        'https://res.cloudinary.com/dgdniqfi9/image/upload/v1568875860/alpacas/simon.jpg', //eslint-disable-line
    },
    {
      name: 'Nick',
      date: 'April 2019',
      text:
        'A great day out for all the family - thank you for a wonderful experience.',
      imgSrc:
        'https://res.cloudinary.com/dgdniqfi9/image/upload/v1568875667/alpacas/nick.jpg', //eslint-disable-line
    },
  ],
};
